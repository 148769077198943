import '../Styles/main.css';
import QuickZipUserForm from '../Components/QuickZipUserForm'

function HomeownerHome () {
    return (
        <main>
            <div className="HomeownerHomeContent">             
                <QuickZipUserForm />                    
            </div>
        </main>
    );
}

export default HomeownerHome;

