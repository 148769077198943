/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:4c97443a-6d08-4fc3-bfa5-1f06961b4ff8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vVVhSvNwb",
    "aws_user_pools_web_client_id": "6hs3pa2n33vcd7ivlth67bm7i8",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://zmph3hdfavaxpd7dqk3xyytzrq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rkrgo7aopfamnjd24vmjo6hcrq",
    "aws_user_files_s3_bucket": "houzey-site-storage232819-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
