import '../Styles/main.css';

function PageNotFound404 () {
    return (
        <main>
            <h3>404 Error! Page Not Found!</h3>
        </main>
    );
}

export default PageNotFound404;